import React from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
    DashboardOutlined,
    TeamOutlined,
    LogoutOutlined,
    IdcardOutlined,
    DollarOutlined,
    GoldOutlined,
    DatabaseOutlined,
} from "@ant-design/icons";

const TopicMenu = () => {
    const location = useLocation();
    const styledTopics = [
        <Menu.Item
            key={1}
            className={location.pathname === "/dashboard" ? "selected" : ""}
        >
            <Link
                className="link-menu text-decoration-none d-flex align-items-center"
                to="/dashboard"
            >
                <DashboardOutlined />
                <span>Sotuv bo'limi</span>
            </Link>
        </Menu.Item>,
        <Menu.Item
            key={2}
            className={location.pathname === "/clients" ? "selected" : ""}
        >
            <Link
                className="link-menu  text-decoration-none d-flex align-items-center"
                to="/clients"
            >
                <TeamOutlined />
                <span>Mijozlar</span>
            </Link>
        </Menu.Item>,
        <Menu.Item
            key={3}
            className={location.pathname === "/workers" ? "selected" : ""}
        >
            <Link
                className="link-menu text-decoration-none d-flex align-items-center"
                to="/workers"
            >
                <DatabaseOutlined />
                <span>Barchasi</span>
            </Link>
        </Menu.Item>,
        <Menu.Item
            key={4}
            className={location.pathname === "/money" ? "selected" : ""}
        >
            <Link
                className="link-menu text-decoration-none d-flex align-items-center"
                to="/money"
            >
                <DollarOutlined />
                <span>Savdolar</span>
            </Link>
        </Menu.Item>,
        <Menu.Item key={6}>
            <Link
                className="link-menu text-decoration-none d-flex align-items-center text-danger"
                to="/"
            >
                <LogoutOutlined />
                <span>Chiqish</span>
            </Link>
        </Menu.Item>,
    ];

    return <Menu mode="inline">{styledTopics}</Menu>;
};
export default TopicMenu;

import React, { useState } from "react";
import "./Seller.css";
import { useEffect } from "react";
import { host } from "../../../config";
import { message, Button, Modal, Form, Input } from "antd";
import HeaderShop from "../../../components/HeaderShop/Header.jsx";

export default function Seller() {
    const [config, setConfig] = useState();
    const [sellers, setSellers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState();
    const [name, setName] = useState();
    const token = localStorage.getItem("token");

    const handleOk = async () => {
        await fetch(host + "/api/sellers", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => message.error(err.name));
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => message.error(err.name));

        fetch(host + "/api/sellers", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setSellers(res);
            })
            .catch((err) => message.error(err.name));
    }, []);

    return (
        <div>
            <HeaderShop config={config} />
            <div className="container-lg">
                <Button
                    style={{ margin: "30px 10px" }}
                    type="primary"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    New Seller
                </Button>

                <div className="row">
                    {sellers?.map((el) => {
                        return (
                            <div className="col seller">
                                <a href={"/shop/sellers/" + el._id}>
                                    <div className="seller_card">{el.name}</div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Modal
                title="Yangi Mijoz"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form>
                    <Form.Item label="Ismi" required>
                        <Input
                            placeholder="Ismi"
                            required={true}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import { host } from "../../config";
import { useNavigate } from "react-router-dom";

const App = () => {
    const [clietns, setClients] = useState();
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    useEffect(() => {
        fetch(host + "/api/clients", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setClients(res);
            })
            .catch((err) => console.log(err));
    }, []);

    console.log(clietns);
    return (
        <div
            className="container-fluid overflow-scroll "
            style={{ height: "100vh" }}
        >
            <div className="p-3 border border-primary-subtle rounded row mt-3">
                <div className="col-6">
                    <span className="mx-4">Sana: 12.01.2024</span>
                    <span className="mx-4">Soat: 13:49</span>
                </div>
                <div className="col-6 text-end">
                    <span className="mx-4">Umumiy: 900gr</span>
                    <span className="mx-4">Umumiy: 900gr</span>
                </div>
                <div className="col text-end btn-group-horizontal mt-3">
                    <button className="btn btn-primary mx-1">Enter</button>
                    <button className="btn btn-outline-danger mx-1">
                        Delete
                    </button>
                </div>
            </div>
            <div className="p-3 border border-primary-subtle rounded row mt-3">
                <div className="col-6">
                    <span className="mx-4">Sana: 12.01.2024</span>
                    <span className="mx-4">Soat: 13:49</span>
                </div>
                <div className="col-6 text-end">
                    <span className="mx-4">Umumiy: 900gr</span>
                    <span className="mx-4">Umumiy: 900gr</span>
                </div>
                <div className="col text-end btn-group-horizontal mt-3">
                    <button className="btn btn-primary mx-1">Enter</button>
                    <button className="btn btn-outline-danger mx-1">
                        Delete
                    </button>
                </div>
            </div>
            <div className="p-3 border border-primary-subtle rounded row mt-3">
                <div className="col-6">
                    <span className="mx-4">Sana: 12.01.2024</span>
                    <span className="mx-4">Soat: 13:49</span>
                </div>
                <div className="col-6 text-end">
                    <span className="mx-4">Umumiy: 900gr</span>
                    <span className="mx-4">Umumiy: 900gr</span>
                </div>
                <div className="col text-end btn-group-horizontal mt-3">
                    <button className="btn btn-primary mx-1">Enter</button>
                    <button className="btn btn-outline-danger mx-1">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default App;

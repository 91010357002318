import HeaderShop from "../../../components/HeaderShop/Header";
import {
    Row,
    Modal,
    Input,
    message,
    Form,
    Card,
    Avatar,
    FloatButton,
    Table,
    Button,
    Select,
} from "antd";
import "./Clients.css";
import { useEffect, useState } from "react";
import { host } from "../../../config";
import { useNavigate } from "react-router-dom";

const { Meta } = Card;
const style = {
    cursor: "pointer",
    background: "#0092ff",
    color: "white",
};

export default function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [config, setConfig] = useState();
    const [data, setData] = useState();
    const [name, setName] = useState();
    const [owner, setOwner] = useState();
    const [url1, setUrl1] = useState("/print");
    const [selectedElement, setSelectedElement] = useState(null);
    const [summ, setSumm] = useState();
    const [newclass, setNewClass] = useState();
    const [TableData, setTableData] = useState();
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const options = [];

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => message.error(err.name));

        fetch(host + "/api/clients/filter", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                classes: _id,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                res.sort((a, b) => a.name.localeCompare(b.name));

                setData(res);
                setTableData(res);
            })
            .catch((err) => message.error(err.name));
    }, []);

    const selectFilter = (owner, index) => {
        var filteredData = data.filter((e) => e.owner === owner);
        setSelectedElement(index);
        setTableData(filteredData);
    };

    const archive = (obj) => {
        fetch(host + "/api/archive", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                client: obj,
            }),
        })
            .then((res) => {
                res.json();
            })
            .then((res) => window.location.reload())
            .catch((err) => message.error(err.name));
    };

    const columns = [
        {
            title: "#",
            dataIndex: "T/R",
            width: "10%",
            render(record, index) {
                return {
                    children: <div>{TableData.indexOf(index) + 1}</div>,
                };
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            sort: true,
            render(record, object, index) {
                return {
                    children: (
                        <div
                            className="tablerow"
                            onClick={() => {
                                navigate(`/shop/clients/${object._id}`);
                            }}
                        >
                            {record}
                        </div>
                    ),
                };
            },
            sorter: (text, object) => text.name.localeCompare(object.name),
            width: "20%",
        },

        {
            title: "Balance",
            dataIndex: "summ",
            render: (text, object, index) => text.toFixed(2),
        },

        {
            title: "Base",
            dataIndex: "owner",
            sorter: (a, b) => a.owner.localeCompare(b.owner),
        },
        {
            title: "Last Upadte",
            dataIndex: "lastupdate",

            render: (text, object) =>
                object?.lastUpdate[0]
                    ? `${object.lastUpdate[
                          object.lastUpdate.length - 1
                      ]?.summ.toFixed(2)} ( ${
                          object.lastUpdate[object.lastUpdate.length - 1]?.date
                      } )`
                    : "",
        },

        {
            title: "Last Upadte",
            dataIndex: "lastupdate",

            render: (text, object) => (
                <Button type="primary" danger onClick={(e) => archive(object)}>
                    Archive
                </Button>
            ),
        },
    ];

    const handleOk = (e) => {
        setIsModalOpen(false);
        fetch(host + "/api/clients", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                name: name,
                summ: summ,
                owner: owner,
                class: _id,
            }),
        })
            .then((res) => {
                res.json();
            })
            .then((res) => window.location.reload())
            .catch((err) => message.error(err.name));
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const _id = window.location.href.split("category")[1].split("/")[1];

    console.log(_id);

    const handleScrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
        });
    };

    const deleteCategory = () => {
        fetch(host + "/api/category", {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                _id: _id,
            }),
        })
            .then((res) => {
                res.json();
            })
            .then((res) => window.location.reload())
            .catch((err) => message.error(err.name));
    };

    const sums = {};
    if (data) {
        for (const item of data) {
            const owner = item.owner;
            const weight = item.summ;

            if (sums[owner]) {
                sums[owner] += weight;
            } else {
                sums[owner] = weight;
            }
        }
    }

    // for (let i = 10; i < 36; i++) {
    //     options.push({
    //         value: i.toString(36) + i,
    //         label: i.toString(36) + i,
    //     });
    // }

    const uniqueNamesArray = Array.from(new Set(data?.map((obj) => obj.class)));

    uniqueNamesArray?.map((el) => {
        options.push({
            value: el || "NotSelected",
            label: el || "Not Selected",
        });
    });
    const handleChange = (value) => {
        setUrl1(`/print?${value}`);
    };
    return (
        <>
            <HeaderShop config={config} />
            <div className="center">
                <h1 style={{ padding: "20px" }}>Clients</h1>
                <Button danger onClick={() => deleteCategory()}>
                    {" "}
                    Delete{" "}
                </Button>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 auto",
                    }}
                >
                    <div
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        style={{
                            width: "100px",
                            margin: "10px",
                            background: "#0092ff",
                            padding: "8px 0",
                            cursor: "pointer",
                            color: "white",
                            fontSize: "30px",
                        }}
                    >
                        +
                    </div>
                    <div
                        onClick={() => {
                            navigate(url1 + "?" + _id);
                        }}
                        style={{
                            width: "100px",
                            margin: "10px",
                            background: "lightgrey",
                            padding: "8px 0",
                            cursor: "pointer",
                            color: "white",
                            fontSize: "30px",
                        }}
                    >
                        🖨️
                    </div>
                    <div
                        onClick={() => {
                            navigate("/print2");
                        }}
                        style={{
                            width: "100px",
                            margin: "10px",
                            background: "lightgrey",
                            padding: "8px 0",
                            cursor: "pointer",
                            color: "white",
                            fontSize: "30px",
                        }}
                    >
                        🖨️
                    </div>
                </div>
                <div
                    style={{ display: "flex", width: "25%", margin: "0 auto" }}
                >
                    <Select
                        mode="tags"
                        style={{
                            width: "100%",
                        }}
                        onChange={handleChange}
                        tokenSeparators={[","]}
                        placeholder={"All"}
                        options={options}
                    />
                </div>
            </div>

            <div
                className="flex"
                style={{
                    justifyContent: "space-around",
                    margin: "30px auto",
                }}
            >
                {Object.entries(sums).map(([owner, value], index) => (
                    <div key={owner}>
                        <Card
                            className="dashboard-card"
                            onClick={(e) => {
                                selectFilter(owner, index);
                            }}
                            style={{
                                border:
                                    selectedElement === index
                                        ? "2px solid #1890ff"
                                        : "",
                                cursor: "pointer",
                                width: 200,
                                verticalAlign: "",
                            }}
                        >
                            <Meta
                                avatar={
                                    <Avatar
                                        className="avatarIcon"
                                        size={"large"}
                                        style={style}
                                    >
                                        {owner}
                                    </Avatar>
                                }
                                title={"General"}
                                description={value.toFixed(2) + " gr"}
                            />
                        </Card>
                    </div>
                ))}
                {/* {data?.map((e) => {
                            return (
                                
                            );
                        })} */}
            </div>
            <Table
                columns={columns}
                dataSource={TableData}
                pagination={false}
                loading={loading}
            />
            <Row gutter={[16, 16]} className="ant-grid">
                {/* {TableData?.map((element) => {
                    return (
                        <Col className="gutter-row" span={3}>
                            <div
                                style={style}
                                onClick={() =>
                                    navigate(`/shop/clients/${element._id}`)
                                }
                            >
                                <h1 className="center">{element.name}</h1>
                                <h3 className="center">
                                    {element.summ.toFixed(2)} gr
                                </h3>
                            </div>
                        </Col>
                    );
                })} */}
            </Row>
            <Modal
                title="Yangi Mijoz"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form>
                    <Form.Item label="Name" required>
                        <Input
                            placeholder="Name"
                            required={true}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="Owner" required>
                        <Input
                            placeholder="Owner"
                            required={true}
                            onChange={(e) => setOwner(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="Balance" required>
                        <Input
                            placeholder="Balance"
                            required={true}
                            type="number"
                            onChange={(e) => setSumm(e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <FloatButton.BackTop
                icon={"⬆️"}
                style={{
                    width: "50px",
                    height: "50px",
                    fontSize: "30px",
                    marginBottom: "70px",
                }}
            />

            <FloatButton
                style={{
                    width: "50px",
                    height: "50px",
                    fontSize: "30px",
                }}
                icon={"⬇️"}
                onClick={() => handleScrollToBottom()}
            />
        </>
    );
}

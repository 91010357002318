import React, { useState } from "react";
import { Table, Button } from "antd";
import "./Dashboard.css";

const App = () => {
    const initialData = [
        ["Cell 1", "Cell 2", "Cell 3"],
        ["Cell 4", "Cell 5", "Cell 6"],
    ];

    const [data, setData] = useState(initialData);
    const [editing, setEditing] = useState({ row: null, col: null });
    const [tempValue, setTempValue] = useState("");

    const TableData = [];
    const columns = [
        {
            title: "T/R",
            dataIndex: "index",
            render: (record, index) => {
                return {
                    items: (
                        <div style={{ width: "10px" }}>
                            {data.indexOf(index) + 1}
                        </div>
                    ),
                };
            },
        },
        {
            title: "Model Nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Model Bazasi",
            dataIndex: "owner",
            key: "owner",
        },
        {
            title: "Vesi",
            dataIndex: "",
            key: "weight",
            render: (e) => <p>{e.weight.toFixed(2)}</p>,
        },
        {
            title: "Ustidigi molda",
            dataIndex: "",
            key: "plus",
            render: (e) => <p>{e.plus.toFixed(2)}</p>,
        },
        {
            title: "O'chirish",
            dataIndex: "",
            key: "x",
            render: (e) => (
                <Button
                    type="primary"
                    onClick={() => console.log(e._id)}
                    danger
                >
                    Delete
                </Button>
            ),
        },
    ];
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-9">
                    <h1>KASSA</h1>
                    <div className="row">
                        <div className="col-4">
                            <input
                                type="text"
                                className="form-control"
                                autoFocus
                            />
                        </div>
                        <div className="col-4">
                            <button className="btn btn-primary">Add</button>
                        </div>
                    </div>
                    <div className=" mt-4">
                        <Table
                            columns={columns}
                            expandable={{
                                expandedRowRender: (record) => (
                                    <p
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        {record.description}
                                    </p>
                                ),
                                rowExpandable: (record) => record.description,
                            }}
                            dataSource={TableData}
                            pagination={false}
                        />
                    </div>
                </div>
                <div
                    style={{
                        minHeight: "300px",
                    }}
                    className="col-3 p-2 px-5  bg-light rounded shadow"
                >
                    <h3 className="my-3 text-center">Umumiy</h3>
                    <div className="row">
                        <div className="col-12 mb-3 fs-5 text-center rounded-top-4 bg-warning">
                            Molda:
                        </div>
                        <div className="col-12 text-end mb-3 fs-4">100gr</div>
                        <div className="col-12 text-center bg-info rounded-top-4 text-light fs-5 mb-3">
                            Pulda:
                        </div>
                        <div className="col-12 shadow-bottom text-end mb-3 fs-4">
                            51 000 000 so'm
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default App;

import React from "react";
import { useEffect, useState } from "react";
import "./Index.css";
// import { Link, useLocation, useNavigate } from "react-router-dom";
import { host } from "../../../config";
import Header from "../../../components/HeaderShop/Header";
import { jwtDecode } from "jwt-decode";
import { Table, Modal, Form, Input, Select } from "antd";
import { render } from "@testing-library/react";
const App = () => {
    const [config, setConfig] = useState();
    const [data, setData] = useState();
    const [userToEdit, setUserToEdit] = useState();
    const token = localStorage.getItem("token");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                console.log("Form Values:", values);
                setIsModalVisible(false);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleOk2 = () => {
        form.validateFields()
            .then((values) => {
                console.log(values);

                fetch(host + "/api/login/register", {
                    method: "POST",
                    headers: {
                        Authorization: "Bareer " + token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: values.username,
                        password: values.password,
                        role: values.role,
                    }),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        window.location.reload();
                    })
                    .catch((err) => console.log(err.message));
                setIsModalVisible2(false);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };

    const deleteUser = (id) => {
        fetch(host + "/api/login/" + id, {
            method: "DELETE",
            headers: {
                Authorization: "Bareer " + token,
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            });
    };
    const user = jwtDecode(token);
    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => console.log(err.message));

        fetch(host + "/api/profile", {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setData(res);
            })
            .catch((err) => console.log(err.message));
    }, []);

    const columns = [
        {
            title: "#",
            dataIndex: "T/R",
            width: "10%",
            render(record, index) {
                return {
                    items: <div>{data.indexOf(index) + 1}</div>,
                };
            },
        },

        {
            title: "Balance",
            dataIndex: "username",
        },

        {
            title: "Role",
            dataIndex: "role",
        },
        {
            title: "Action",
            render: (txt, obj) => {
                return (
                    <div>
                        <button
                            className="btn btn-success mx-3"
                            onClick={() => {
                                showModal();
                                setUserToEdit(obj);
                            }}
                        >
                            Edit
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                                deleteUser(obj._id);
                            }}
                        >
                            Delete
                        </button>
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <Header config={config} />
            <div className="center">
                <div
                    className="flex"
                    style={{
                        justifyContent: "space-around",
                        margin: "30px auto",
                    }}
                >
                    <div className="avatar">
                        <div className="circle">
                            <img src="/img/default.png" alt="" />
                        </div>
                    </div>
                    <div className="info">
                        <h1>Hi, {user.username} 👋🏻</h1>
                        <br />
                        <div className="text-end">
                            <button
                                className=" m-3 btn btn-primary"
                                onClick={showModal2}
                            >
                                {" "}
                                + Add Member
                            </button>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>

            <Modal
                title="Edit User"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ modifier: "public" }}
                >
                    <Form.Item
                        name="Username"
                        initialValue={userToEdit?.username}
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: "Please fill the field!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="New Password"
                        label="New Password"
                        rules={[
                            {
                                required: true,
                                message: "Please fill the field!",
                            },
                        ]}
                    >
                        <Input type="password" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="New User"
                open={isModalVisible2}
                onOk={handleOk2}
                onCancel={handleCancel2}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ modifier: "public" }}
                >
                    <Form.Item
                        name="username"
                        initialValue={userToEdit?.username}
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: "Please fill the field!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[
                            {
                                required: true,
                                message: "Please fill the field!",
                            },
                        ]}
                    >
                        <Select>
                            <option value="admin">Admin</option>
                            <option value="sotuvchi">Sotuvchi</option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: "Please fill the field!",
                            },
                            {
                                len: 6,
                                message: "Min 6",
                            },
                        ]}
                    >
                        <Input type="password" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default App;

import React, { useEffect, useState } from "react";
import { host } from "../../config";
import { useNavigate } from "react-router-dom";

const App = () => {
    const [clietns, setClients] = useState();
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    useEffect(() => {
        fetch(host + "/api/clients", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setClients(res);
            })
            .catch((err) => console.log(err));
    }, []);

    const fetchShop = () => {};

    console.log(clietns);
    return (
        <div
            className="container-fluid overflow-scroll "
            style={{ height: "100vh" }}
        >
            <div className="text-center d-flex w-25 mx-auto">
                <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                        fetchShop();
                    }}
                />
                <button className="btn btn-dark mx-2">New</button>
            </div>
            <div className="row mt-5 justify-content-center column-gap-3 row-gap-2">
                {console.log(clietns)}
                {clietns &&
                    clietns?.map((client) => {
                        return (
                            <div
                                style={{ minHeight: "90px" }}
                                onClick={() => {
                                    navigate("/clients/" + client._id);
                                }}
                                className="col-2 bg-dark cursor-pointer rounded-3 text-center text-light px-3 py-2"
                            >
                                {client.name}
                                <p className="mt-4">
                                    {client.summ.toFixed(2)} gr
                                </p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default App;

import React, { useEffect, useState } from "react";
import { host } from "../../config";
import { useNavigate } from "react-router-dom";
import { Form, Table, Button, Modal, Input } from "antd";
const App = () => {
    const [clietns, setClients] = useState();
    const token = localStorage.getItem("token");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdetModal, setIsUpdateModal] = useState(false);
    const [model, setModel] = useState();
    const [config, setConfig] = useState();
    // const [data, setData] = useState();

    const data = [
        {
            name: "A27",
            price: 5000,
        },
        {
            name: "A27",
            price: 5000,
        },
        {
            name: "A27",
            price: 5000,
        },
    ];

    const fetchPost = (e) => {
        fetch(host + `/api/sellers/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(e),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => console.log(err.message));
    };

    const navigate = useNavigate();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const updateModal = (e) => {
        setModel(e);
        setIsUpdateModal(true);
    };

    const updateOK = () => {
        setIsUpdateModal(false);
    };
    const updateCancel = () => {
        setIsUpdateModal(false);
    };

    const fetchDelet = (id) => {
        fetch(host + "/api/database", {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                id: id,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => console.log(err.message));
    };

    const columns = [
        {
            title: "R",
            key: "name",
            dataIndex: "",
            render: (record, index) => {
                return <p>{data.indexOf(index) + 1}</p>;
            },
        },
        {
            title: "Model Nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Ustidigi puli",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "Delete",
            dataIndex: "",
            key: "x",
            render: (e) => (
                <Button type="primary" onClick={() => updateModal(e)}>
                    Update
                </Button>
            ),
        },
        {
            title: "O'chirish",
            dataIndex: "",
            key: "x",
            render: (e) => (
                <Button type="primary" onClick={() => fetchDelet(e._id)} danger>
                    Delete
                </Button>
            ),
        },
    ];
    return (
        <div
            className="container-fluid overflow-scroll "
            style={{ height: "100vh" }}
        >
            <div className="justify-content-end d-flex">
                <button className="btn btn-dark mx-2" onClick={showModal}>
                    New
                </button>
            </div>

            <div class="table-responsive mt-3">
                <Table
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record) => (
                            <p
                                style={{
                                    margin: 0,
                                }}
                            >
                                {record.description}
                            </p>
                        ),
                        rowExpandable: (record) => record.description,
                    }}
                    dataSource={data}
                    pagination={false}
                />

                <Modal
                    title="Yangi model qo'shish"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={false}
                >
                    <Form
                        onFinish={(e) => {
                            fetchPost(e);
                        }}
                        style={{
                            maxWidth: 300,
                            margin: "0 auto",
                        }}
                    >
                        <Form.Item
                            name="name"
                            label="Nomi"
                            rules={[
                                {
                                    required: true,
                                    message: "Nomini yozing",
                                },
                            ]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                        <Form.Item
                            name="price"
                            label="Narxi"
                            rules={[
                                {
                                    required: true,
                                    message: "Narxini yozing",
                                },
                            ]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                        <Button
                            type="primary"
                            style={{
                                width: "100%",
                            }}
                            htmlType="submit"
                        >
                            Qo'shish
                        </Button>
                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default App;

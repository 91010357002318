import {
    Popconfirm,
    Button,
    Table,
    message,
    Input,
    InputNumber,
    Form,
} from "antd";
import HeaderShop from "../../../../components/HeaderShop/Header";
import { useLocation, useNavigate } from "react-router-dom";
import "./Inner.css";
import { useEffect, useState } from "react";
import { host } from "../../../../config";

export default function App() {
    const [user, setUser] = useState();
    const [config, setConfig] = useState();
    const [data, setData] = useState();
    const [newName, setNewName] = useState();
    const [newSumm, setNewSumm] = useState();
    const [newClass, setNewClass] = useState();
    const [tableData, setTableData] = useState(data);
    const location = useLocation();
    const userID = location.pathname.split("/")[3];
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const onInputChange = (key, index) => (e) => {
        const newData = [...data];
        newData[index][key] = e.target.value;
        setTableData(newData);
    };

    const updateFunc = (obj) => {
        fetch(host + "/api/clients/processUpdate", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                process: obj,
                userId: userID,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                window.location.reload();
            })
            .catch((err) => {
                // Handle errors here
                message.error(err);
            });
    };

    const deleteFunc = (obj) => {
        fetch(host + "/api/clients/processDelete", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                process: obj,
                userId: userID,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                // Handle the result here
                window.location.reload();
                // You can use the result in your component state or trigger other actions
            })
            .catch((err) => {
                // Handle errors here
                message.error(err);
            });
    };
    const columns = [
        {
            title: "T/R",
            dataIndex: "index",
            render(record, index) {
                return {
                    children: <div>{data.indexOf(index) + 1}</div>,
                };
            },
        },
        {
            title: "Sana",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Oldi berdi",
            dataIndex: "summ",
            key: "summ",
            render: (text, record, index) => (
                <Input value={text} onChange={onInputChange("summ", index)} />
            ),
        },
        {
            title: "Comment",
            key: "plus",
            dataIndex: "comment",
            render: (text, record, index) => (
                <Input
                    value={text}
                    onChange={onInputChange("comment", index)}
                />
            ),
        },
        {
            title: "Action",
            render: (text, object, index) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={(e) => updateFunc(object)}
                        >
                            Update
                        </Button>
                        <Button danger onClick={() => deleteFunc(object)}>
                            Delete
                        </Button>
                    </div>
                );
            },
        },
    ];

    const confirm = (e) => {
        fetch(host + "/api/clients", {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                _id: e,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.deletedCount) {
                    navigate("/shop/clients");
                }
            })
            .catch((err) => message.error(err));
    };
    const cancel = (e) => {
        message.error("Click on No");
    };

    const update = (e) => {
        fetch(host + `/api/clients/${userID}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },

            body: JSON.stringify({
                name: newName,
                class: newClass,
                summ: newSumm,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => message.error(err.name));
    };

    const fetchPost = (e) => {
        fetch(host + `/api/clients/${userID}/update`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(e),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => message(err));
    };

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => console.log(err));

        fetch(host + `/api/clients/${userID}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setUser(res);
                setData(res.process);
                setTableData(res.process);
            })
            .catch((err) => message(err));
    }, []);

    return (
        <>
            <HeaderShop config={config} />
            <div className="center">
                <br />
                <label key={"labelname"} htmlFor="name">
                    Ismi:
                </label>
                <input
                    className="name-hidden"
                    key={"name"}
                    type="text"
                    id="name"
                    onChange={(e) => {
                        setNewName(e.target.value);
                    }}
                    defaultValue={user?.name}
                />
                <br />

                <label
                    htmlFor="summ"
                    style={{
                        textAlign: "right",
                    }}
                    key={"labelsumm"}
                >
                    +/-:
                </label>
                <input
                    className="price-hidden"
                    type="text"
                    id="summ"
                    onChange={(e) => {
                        setNewSumm(e.target.value);
                    }}
                    defaultValue={user?.summ.toFixed(2)}
                    key={"summ"}
                    suffix=" gr"
                />
                <br />

                <Popconfirm
                    title="Mijozni yangilash"
                    description="Tasdiqlash orqali siz mijozni yangi ma'lumotlarini saqlagan bo'lasiz."
                    onConfirm={() => update(user?._id)}
                    onCancel={() => cancel()}
                    okText="Yangilash"
                    cancelText="Bekor qilish"
                >
                    <Button type="primary">Update</Button>
                </Popconfirm>

                <Popconfirm
                    title="Mijozni o'chirish"
                    description="Bu harakatni ortga qaytarib bo'lmaydi !!!"
                    onConfirm={() => confirm(user?._id)}
                    onCancel={() => cancel()}
                    okText="O'chirish"
                    cancelText="Bekor qilish"
                >
                    <Button danger>Delete</Button>
                </Popconfirm>
            </div>

            <div className="margin">
                <Form
                    onFinish={(e) => {
                        fetchPost(e);
                    }}
                    style={{
                        maxWidth: "300px",
                        margin: "30px auto",
                    }}
                >
                    <Form.Item
                        name="summ"
                        label="Vesi"
                        rules={[
                            {
                                required: true,
                                message: "Vesini yozing",
                            },
                        ]}
                    >
                        <Form.Item name="summ" noStyle>
                            <InputNumber
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="comment" label="Comment">
                        <Input placeholder="" />
                    </Form.Item>
                    <Button
                        type="primary"
                        style={{
                            width: "100%",
                        }}
                        htmlType="submit"
                    >
                        Input
                    </Button>
                </Form>
            </div>

            <div className="margin">
                <Table
                    columns={columns}
                    // scroll={{ x: 150, y: 500 }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <p
                                style={{
                                    margin: 0,
                                }}
                            >
                                {record.description}
                            </p>
                        ),
                        rowExpandable: (record) => record.description,
                    }}
                    rowClassName={(record, index) =>
                        record.amount > 50 ? "red" : "green"
                    }
                    dataSource={tableData}
                    pagination={false}
                />
            </div>
        </>
    );
}
